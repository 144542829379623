<template>
    <div class="label-dropdown">
        <button v-if="$store.state.activeLabel" @click="toggleDropdown" class="navbar-link">
            {{ $store.state.activeLabel.name }} <FontAwesomeIcon icon="caret-down"/>
        </button>
        <transition name="collapse">
            <div v-show="!collapsed" class="dropdown">
                <button
                    v-for="label in labels"
                    :key="label.id"
                    :class="{active: label == $store.state.activeLabel}"
                    @click="selectLabel(label)"
                    class="dropdown-item">
                    {{ label.name }}
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'LabelDropdown',
    data() {
        return {
            labels: [],
            scrollElement: null,
            scrolling: null,
            isScrolledLeft: true,
            isScrolledRight: true,
            collapsed: true
        }
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        selectLabel(label) {
            this.$store.commit('selectLabel', label)
            this.closeDropdown()
        },
        toggleDropdown() {
            if (this.collapsed) {
                this.collapsed = false
                document.body.classList.add('overlay')
            }
            else {
                this.collapsed = true
                document.body.classList.remove('overlay')
            }
        },
        closeDropdown() {
            this.collapsed = true
            document.body.classList.remove('overlay')
        }
    },
    created() {
        this.axios.get('labels/')
        .then(resp => {
            this.labels = resp.data.labels
            if (this.labels.length) {
                this.$store.commit('selectLabel', this.labels[0])
            }
        })
        .catch(() => {})
    }
}
</script>

<style scoped>

.navbar-link {
    white-space: nowrap;
    overflow-x: hidden;
    margin: 0;
}
.dropdown {
    position: fixed;
    top: 3.5rem;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #f5f5f5;
    overflow-y: auto;
    height: calc(100vh - 3.5rem);
}
.dropdown-item {
    display: block;
    padding: .25rem .5rem;
    margin: .25rem .5rem;
    text-decoration: none;
    border-radius: .5rem;
    transition: background-color .3s;
    color: black;
    border: 1px solid transparent;
    background: none;
    font-size: 1rem;
}
.dropdown-item.active {
    background-color: #cfffe5;
    border: 1px solid #98ff98;
}
.collapse-enter,
.collapse-leave-to {
    height: 0!important;
}
.collapse-enter-active,
.collapse-leave-active {
    overflow: hidden;
    transition: height .3s ease;
}
</style>